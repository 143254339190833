import axios from 'axios';
import router from '../../router';
import { checkCanModifyStudentData } from '@/authHelperFunctions';
import { isAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent } from '@/authHelperFunctions';

export default {
  namespaced: true,
  state: {
    schueler: {
        id: null,
        user_id: null,
        schueler_vorname: null,
        schueler_name: null,
        geburtsdatum: null,
        nationalitaet: null,
        sorgeberechtigte_person: null,
        schueler_strasse: null,
        schueler_hausnr: null,
        schueler_plz: null,
        schueler_ort: null,
        mutter_vorname: null,
        mutter_name: null,
        mutter_strasse: null,
        mutter_hausnr: null,
        mutter_plz: null,
        mutter_ort: null,
        mutter_telefon: null,
        mutter_handy: null,
        mutter_email: null,
        vater_vorname: null,
        vater_name: null,
        vater_strasse: null,
        vater_hausnr: null,
        vater_plz: null,
        vater_ort: null,
        vater_telefon: null,
        vater_handy: null,
        vater_email: null,
        betreuungsart: null,
        betreuungsfall_abgeschlossen: null,
        anforderung_schule: null,
        einverstaendnis_eltern: null,
        einverstaendnis_begabungsdiagnostik: null,
        schweigepflichtentbindung: null,
        schule: null,
        jahrgangsstufe: null,
        klasse: null,
        klassenleitung_vorname: null,
        klassenleitung_name: null,
        klassenleitung_anrede: null,
        primaerer_foerderschwerpunkt: null,
        weiterer_foerderschwerpunkt: null,
        einschulung: null,
        zurueckstellung: null,
        wiederholung_boolean: null,
        wiederholung_information: null,
        laufbahn: null,
        sonstiges: null,
        nachmittagsbetreuung: null,
        notenbefreiung: null,
        nachteilsausgleich_prozent: null,
        nachteilsausgleich_text: null,
        notenschutz: null,
        betreuungszeitraeume: null,
        alter: null,
        betreuungsart_options: null,
        foerderschwerpunkt_options: null,
        verwandtschaftsbeziehung_options: null,
        contact_persons: [
            {
                id: 1,
                erziehungsberechtigte_person: 1,
                verwandtschaftsbeziehung: null,
                institution: null,
                vorname: null,
                name: null,
                strasse: null,
                hausnr: null,
                plz: null,
                ort: null,
                telefon: null,
                handy: null,
                email: null,
                neue_kontaktperson: true
            },
            {
                id: 2,
                erziehungsberechtigte_person: 2,
                verwandtschaftsbeziehung: null,
                institution: null,
                vorname: null,
                name: null,
                strasse: null,
                hausnr: null,
                plz: null,
                ort: null,
                telefon: null,
                handy: null,
                email: null,
                neue_kontaktperson: true
            }
        ]
      },
      alreadyExistingStudentsToBeCreated: null,
      betreuungsart_options: [],
      contactPersonToDelete: {},
      authenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent: false,
      foerderschwerpunkt_options: [],
      verwandtschaftsbeziehung_options: [],
      betreuungsfallAbgeschlossenValueWhenSavingStudent: 0,
      schuelerContactPersonMaxId: 2,
      schuelerSuccessfullyCreated: false,
      schuelerSuccessfullyEdited: false,
      schueler_to_delete_id: null,
      old_betreuungsfall_abgeschlossen: null
  },
  getters: {
  },
  mutations: {
    deleteContactPersonFromState(state){
        let index = state.schueler.contact_persons.findIndex(contactPerson => contactPerson.id === state.contactPersonToDelete.id)
        if (index !== -1){
            state.schueler.contact_persons.splice(index, 1);
        }
    },
    setSchueler(state, schueler) {
        if (schueler) {
            let contactPersonMaxId = 0;
            if (schueler.contact_persons !== null && schueler.contact_persons.length) {
                contactPersonMaxId = Math.max.apply(Math, schueler.contact_persons.map(function(o) { return o.id; }));
            }

            // Add empty objects for Erziehungsberechtigte Person 1 and 2
            // to the first two positions of the array, if these objects don't exit yet.
            // (This is required for segments for Erziehungsberechtigte Person 1 and 2
            // to be displayed in EditSchueler)
            for (let i = 1; i <= 2; i++) {
                if (!schueler.contact_persons.some(contactPerson => contactPerson.erziehungsberechtigte_person === i)) {
                    contactPersonMaxId = contactPersonMaxId + 1;
                    schueler.contact_persons.splice(i-1,0,{
                        id: contactPersonMaxId,
                        erziehungsberechtigte_person: i,
                        verwandtschaftsbeziehung: null,
                        institution: null,
                        vorname: null,
                        name: null,
                        strasse: null,
                        hausnr: null,
                        plz: null,
                        ort: null,
                        telefon: null,
                        handy: null,
                        email: null,
                        neue_kontaktperson: true
                    });
                  }
            }

            // Sort by the value of erziehungsberechtigte_person,
            // in case Erziehungsberechtigte Personen were the last contact persons,
            // who were added to contact_persons table
            schueler.contact_persons = schueler.contact_persons.sort((a, b) => {
              if (a.erziehungsberechtigte_person === 1) return -1;
              if (b.erziehungsberechtigte_person === 1) return 1;
              if (a.erziehungsberechtigte_person === 2) return -1;
              if (b.erziehungsberechtigte_person === 2) return 1;
              return 0;
            });

            state.schueler = schueler;
            state.schuelerContactPersonMaxId = contactPersonMaxId;
        }
        else {
          state.schueler = {};
        }
    },
    setAlreadyExistingStudentsToBeCreated(state, alreadyExistingStudentsToBeCreated) {
      state.alreadyExistingStudentsToBeCreated = alreadyExistingStudentsToBeCreated;
    },
    setAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent(state, authenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent) {
        state.authenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent = authenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent;
    },
    setContactPersonToDelete(state, contactPersonToDeleteId) {
      if (contactPersonToDeleteId === null) {
        state.contactPersonToDelete = {};
      }
      else {
        let contactPersonToDelete = state.schueler.contact_persons.find(obj => {return obj.id === contactPersonToDeleteId});
        state.contactPersonToDelete = JSON.parse(JSON.stringify(contactPersonToDelete));
      }
    },
    setSchuelerContactPersonMaxId(state, schuelerContactPersonMaxId) {
      state.schuelerContactPersonMaxId = schuelerContactPersonMaxId;
    },
    setSchuelerId(state, schueler_id) {
      state.schueler.id = schueler_id;
    },
    setSchuelerSuccessfullyCreated(state, schuelerSuccessfullyCreated) {
      state.schuelerSuccessfullyCreated = schuelerSuccessfullyCreated;
    },
    setSchuelerSuccessfullyEdited(state, schuelerSuccessfullyEdited) {
      state.schuelerSuccessfullyEdited = schuelerSuccessfullyEdited;
    },
    setBetreuungsartOptions(state, betreuungsart_options) {
      state.betreuungsart_options = betreuungsart_options;
    },
    setFoerderschwerpunktOptions(state, foerderschwerpunkt_options) {
      state.foerderschwerpunkt_options = foerderschwerpunkt_options;
    },
    setVerwandtschaftsbeziehungOptions(state, verwandtschaftsbeziehung_options) {
      state.verwandtschaftsbeziehung_options = verwandtschaftsbeziehung_options;
    },
    setSchuelerToDeleteId(state, schueler_to_delete_id) {
      state.schueler_to_delete_id = schueler_to_delete_id;
    },
    setUserId(state) {
      state.schueler.user_id = this.state.auth.user.id;
    },
    setOldBetreuungsfallAbgeschlossen(state, old_betreuungsfall_abgeschlossen) {
      state.old_betreuungsfall_abgeschlossen = old_betreuungsfall_abgeschlossen;
    },
    setBetreuungsfallAbgeschlossenValueWhenSavingStudent(state, betreuungsfallAbgeschlossenValueWhenSavingStudent) {
      state.betreuungsfallAbgeschlossenValueWhenSavingStudent = betreuungsfallAbgeschlossenValueWhenSavingStudent;
    }
  },
  actions: {
    getSchueler({commit, state, rootGetters, rootState}){
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/api/schueler/' + state.schueler.id + '/edit'))
          .then((res) => {
            const schueler = res.data;
            commit('setSchueler', schueler);
            commit('setBetreuungsartOptions', res.data.betreuungsart_options);
            commit('setFoerderschwerpunktOptions', res.data.foerderschwerpunkt_options);
            commit('setVerwandtschaftsbeziehungOptions', res.data.verwandtschaftsbeziehung_options);
            commit('setOldBetreuungsfallAbgeschlossen', res.data.betreuungsfall_abgeschlossen);
            let setIsAssigned = false;
            if (isAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent(rootState.auth.user.role, rootState.auth.user.id, schueler)){
                setIsAssigned = true;
            }
            commit('setAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent', setIsAssigned);
            const canModify = checkCanModifyStudentData(rootState.auth.schoolType, rootState.auth.user.role, rootState.auth.user.id, schueler);
            commit('auth/setCanModifyStudentData', canModify, {root: true});
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setSchueler', null);
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    getDropdownOptions({commit, rootGetters}){
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/api/schueler/create'))
          .then((res) => {
            commit('setBetreuungsartOptions', res.data.betreuungsarten);
            commit('setFoerderschwerpunktOptions', res.data.foerderschwerpunkte);
            commit('setVerwandtschaftsbeziehungOptions', res.data.verwandtschaftsbeziehungen);
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    checkIfStudentAlreadyExists({commit, rootGetters}, schuelerData) {
      commit('setAlreadyExistingStudentsToBeCreated', null);
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/api/schueler/check-if-student-already-exists'), schuelerData)
          .then((res) => {
            if (res.data !== "") {
              commit('setAlreadyExistingStudentsToBeCreated', res.data);
            }
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    createStudent({commit, rootGetters}, schuelerData) {
      commit('setLoading', true, {root: true});
      commit('setUserId');
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/api/schueler'), schuelerData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            if (schuelerData.betreuungsfall_abgeschlossen && parseInt(schuelerData.betreuungsfall_abgeschlossen) === 1) {
              commit('setBetreuungsfallAbgeschlossenValueWhenSavingStudent', 1);
            }
            commit('setSchuelerSuccessfullyCreated', true);
            resolve(res);
            router.push('/');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    editStudent({commit, state, rootGetters}, schuelerData) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.patch(rootGetters.getUrl('/api/schueler/' + state.schueler.id), schuelerData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            if (schuelerData.betreuungsfall_abgeschlossen && parseInt(schuelerData.betreuungsfall_abgeschlossen) === 1) {
              commit('setBetreuungsfallAbgeschlossenValueWhenSavingStudent', 1);
            }
            commit('setSchuelerSuccessfullyEdited', true);
            resolve(res);
            router.push('/');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    deleteSchueler({commit, state, rootGetters}) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.delete(rootGetters.getUrl('/api/schueler/' + state.schueler_to_delete_id))
          .then((res) => {
            commit('setLoading', false, {root: true});
            commit('allSchuelerModule/deleteSchuelerFromState', { id: state.schueler_to_delete_id, canSeeUsers: rootGetters['auth/canSeeUsers'] }, {root: true});
            // Set the id of the student that was last accessed to null in the state if this student is being deleted,
            // so that it is no longer possible to try to access this student via the Betreuungsakt button in the bottom menu (FLOG)
            if (state.schueler.id && state.schueler.id === state.schueler_to_delete_id) {
                commit('setSchuelerId', null);
            }
            commit('setSchuelerToDeleteId', null);
            resolve(res);
            router.push('/');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            commit('setSchuelerToDeleteId', null);
            reject(error);
          });
      });
    },
    setAlreadyExistingStudentsToBeCreated({commit}, payload) {
        commit('setAlreadyExistingStudentsToBeCreated', payload.alreadyExistingStudentsToBeCreated);
    },
    setSchuelerId({commit}, payload) {
        commit('setSchuelerId', payload.schueler_id);
    },
    setSchuelerToDeleteId({commit}, payload) {
        commit('setSchuelerToDeleteId', payload.schueler_id);
    }
  },
  modules: {}
}
