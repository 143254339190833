import axios from 'axios';

export default {
  namespaced: true,
  state: {
    allUsers: null,
    allUsersWhoCanBeAssignedToStudentsGy: null
  },
  getters: {
    sortAllUsersState: (state) => (sort_order, user_property) => {
      let allUsersSorted = state.allUsers;

        function propComparator(order, prop) {
          if (prop === "is_blocked"){
            return function(a, b) {
              if (order === "blocked_first") {
                return (a.is_blocked === b.is_blocked)? 0 : a.is_blocked? -1 : 1;
              }
              else {
                return (a.is_blocked === b.is_blocked)? 0 : a.is_blocked? 1 : -1;
              }
            }
          }
          else {
            return function(a, b) {
              var a_lowerCase = "";
              var b_lowerCase = "";
              a_lowerCase = a[prop].toLowerCase();
              b_lowerCase = b[prop].toLowerCase();

              if (order === "desc") {
                return b_lowerCase.localeCompare(a_lowerCase, 'de', {sensitivity: 'base'})
              }
              else {
                return a_lowerCase.localeCompare(b_lowerCase, 'de', {sensitivity: 'base'})
              }
            }
          }
        }

      allUsersSorted.sort(propComparator(sort_order, user_property));
    }
  },
  mutations: {
    setAllUsers(state, allUsers) {
      state.allUsers = allUsers;
    },
    setAllUsersWhoCanBeAssignedToStudentsGy(state, allUsersWhoCanBeAssignedToStudentsGy) {
      state.allUsersWhoCanBeAssignedToStudentsGy = allUsersWhoCanBeAssignedToStudentsGy;
    },
    deleteUserFromState(state, id){
     var index = state.allUsers.findIndex(user => user.id === id)
     if (index !== -1){
        state.allUsers.splice(index, 1);
     }
    }
  },
  actions: {
    getAllUsers({commit, rootGetters, getters}){
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/admin/edit-user'))
        .then((res) => {
          commit('setAllUsers', res.data);
          getters.sortAllUsersState('asc','name','');
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setAllUsers', null);
          commit('setLoading', false, {root: true});
        });
    },
    getAllUsersWhoCanBeAssignedToStudentsGy({commit, rootGetters}){
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/api/edit-user'))
        .then((res) => {
          commit('setAllUsersWhoCanBeAssignedToStudentsGy', res.data);
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setAllUsersWhoCanBeAssignedToStudentsGy', null);
          commit('setLoading', false, {root: true});
        });
    }
  }
}