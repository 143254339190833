import axios from 'axios';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    allSchueler: [],
    allActiveSchuelerCases: [],
    allClosedSchuelerCases: [],
    allSchools: null,
    allSchoolsWithActiveSchuelerCases: [],
    allSchoolsWithClosedSchuelerCases: [],
    showActiveCases: true
  },
  getters: {
    allSchuelerStateInUse: (state, getters, rootState, rootGetters) => {
        if (rootState.auth.user && rootGetters['auth/canSeeUsers'])
        {
            if (state.showActiveCases === true)
            {
                return state.allActiveSchuelerCases;
            }
            else {
                return state.allClosedSchuelerCases;
            }
        }
        else {
            return state.allSchueler;
        }
    },
    allSchoolsStateInUse: (state, getters, rootState) => {
        if (rootState.auth.user && rootState.auth.user.is_admin === 1)
        {
            if (state.showActiveCases === true)
            {
                return state.allSchoolsWithActiveSchuelerCases;
            }
            else {
                return state.allSchoolsWithClosedSchuelerCases;
            }
        }
        else {
            return state.allSchools;
        }
    },
    stateSorted: (state) => (sort_order, schueler_property1, schueler_property2, state_to_sort, is_user) => {
      let stateSorted = state[state_to_sort];

        function propComparator(order, prop1, prop2, is_user) {
            return function(a, b) {
                var a_lowerCase = "";
                var b_lowerCase = "";
                if (typeof is_user !== 'undefined') {
                    a_lowerCase = a['user_sfz'][prop1] ? a['user_sfz'][prop1].toLowerCase() : "";
                    b_lowerCase = b['user_sfz'][prop1] ? b['user_sfz'][prop1].toLowerCase() : "";
                }
                else if (typeof prop2 !== 'undefined' && prop2.length !== 0) {
                    a_lowerCase = a[prop1].toLowerCase() + a[prop2].toLowerCase();
                    b_lowerCase = b[prop1].toLowerCase() + b[prop2].toLowerCase();
                }
                else {
                    a_lowerCase = a[prop1] ? a[prop1].toLowerCase() : "";
                    b_lowerCase = b[prop1] ? b[prop1].toLowerCase() : "";
                }

                if (order === "desc") {
                    return b_lowerCase.localeCompare(a_lowerCase, 'de', {sensitivity: 'base'})
                }
                else {
                    return a_lowerCase.localeCompare(b_lowerCase, 'de', {sensitivity: 'base'})
                }
            }
        }

        stateSorted.sort(propComparator(sort_order, schueler_property1, schueler_property2, is_user));
    }
  },
  mutations: {
    setAllSchueler(state, allSchueler) {
        // allSchueler.user_sfz.name is accessed in SchuelerUserAssignIndividually, which leads to an error,
        // if no user is associated with the schueler. Therefore, in this case user_sfz.name null is added to schueler.user_sfz (FLOG - 10.08.2023)
        if (this.state.auth.schoolType === "SFZ" && this.state.auth.user && this.state.auth.user.is_admin === 1)
        {
            allSchueler.forEach(function (e) {
                if (e.user_sfz === null){
                    e.user_sfz = { name: null }
                }
            })
        }
        state.allSchueler = allSchueler;
    },
    setAllActiveSchuelerCases(state){
        var allActiveSchuelerCases = JSON.parse(JSON.stringify(state.allSchueler));
        allActiveSchuelerCases = allActiveSchuelerCases.filter(one_schueler => {
          return one_schueler.betreuungsfall_abgeschlossen === 0
        })
        state.allActiveSchuelerCases = allActiveSchuelerCases;
    },
    setAllClosedSchuelerCases(state){
        var allClosedSchuelerCases = JSON.parse(JSON.stringify(state.allSchueler));
        allClosedSchuelerCases = allClosedSchuelerCases.filter(one_schueler => {
          return one_schueler.betreuungsfall_abgeschlossen === 1
        })
        state.allClosedSchuelerCases = allClosedSchuelerCases;
    },
    setAllSchools(state) {
        let allSchueler;
        allSchueler = state.allSchueler;
        var schoolType = this.state.auth.schoolType;
        let allSchoolsArray = [];
        let schoolNameArray = [...new Set(allSchueler.map(item => item.schule))];

        // Filter out all students of each school
        schoolNameArray.forEach(function (e) {
          let school = {};
          let schuelerOfOneSchoolArray = allSchueler.filter(obj => {
            return obj.schule === e
          })

          // If a user is assigned to all students of a school, this user is written into school.user_sfz. Otherwise school.user_sfz = null.
          if (schoolType === "SFZ") {
              var current_user;
              for (var i = 0; i < schuelerOfOneSchoolArray.length; i++) {
                if (schuelerOfOneSchoolArray[i].user_sfz){
                    school.user_sfz = schuelerOfOneSchoolArray[i].user_sfz.name;
                    if (typeof current_user !== 'undefined' && current_user !== schuelerOfOneSchoolArray[i].user_sfz.name) {
                      school.user_sfz = null;
                      break;
                    }
                    current_user = schuelerOfOneSchoolArray[i].user_sfz.name;
                }
              }
          }
          school.schueler = schuelerOfOneSchoolArray;
          school.school_name = e;
          allSchoolsArray.push(school);
        })
      state.allSchools = allSchoolsArray;
    },
    setAllSchoolsWithActiveSchuelerCases(state){
        var allSchoolsWithActiveSchuelerCases = JSON.parse(JSON.stringify(state.allSchools));
        allSchoolsWithActiveSchuelerCases = allSchoolsWithActiveSchuelerCases.filter(one_school => {
            var active_betreuungsfaelle_exist = false;
            for (var i = 0; i < one_school.schueler.length; i++) {
              if (one_school.schueler[i].betreuungsfall_abgeschlossen === 0){
                active_betreuungsfaelle_exist = true;
                break;
              }
            }
            return active_betreuungsfaelle_exist;
        });
        state.allSchoolsWithActiveSchuelerCases = allSchoolsWithActiveSchuelerCases;
    },
    setAllSchoolsWithClosedSchuelerCases(state){
        var allSchoolsWithClosedSchuelerCases = JSON.parse(JSON.stringify(state.allSchools));
        allSchoolsWithClosedSchuelerCases = allSchoolsWithClosedSchuelerCases.filter(one_school => {
            var closed_betreuungsfaelle_exist = false;
            for (var i = 0; i < one_school.schueler.length; i++) {
              if (one_school.schueler[i].betreuungsfall_abgeschlossen === 1){
                closed_betreuungsfaelle_exist = true;
                break;
              }
            }
            return closed_betreuungsfaelle_exist;
        });
        state.allSchoolsWithClosedSchuelerCases = allSchoolsWithClosedSchuelerCases;
    },
    deleteSchuelerFromState(state, payload){
        let schueler_state_in_use;
        if (this.state.auth.user && payload.canSeeUsers)
        {
            if (state.showActiveCases === true)
            {
                schueler_state_in_use = "allActiveSchuelerCases";
            }
            else {
                schueler_state_in_use = "allClosedSchuelerCases";
            }
        }
        else {
            schueler_state_in_use = "allSchueler";
        }

        let index = state[schueler_state_in_use].findIndex(schueler => schueler.id === payload.id)
        if (index !== -1){
            state[schueler_state_in_use].splice(index, 1);
        }
    },
    setShowActiveCases(state, showActiveCases) {
      state.showActiveCases = showActiveCases;
    }
  },
  actions: {
    getAllSchueler({commit, rootGetters, rootState, getters}, payload){
      commit('setLoading', true, {root: true});
      commit('config/addSchoolToServerUrl', rootState.auth.school, {root: true});
      let only_active_cases = payload.only_active_cases === true ? '1' : '';
      axios.get(rootGetters.getUrl('/api/all-schueler/' + only_active_cases))
        .then((res) => {
          res.data.forEach(function (e) {
            if (!e.schule){
              e.schule = "Keiner Schule zugeordnet";
            }
          });
          commit('setAllSchueler', res.data);
          if (rootState.auth.schoolType === "SFZ") {
            commit('setAllSchools');
          }
          if (payload.sort_by_school === true){
            getters.stateSorted('asc','school_name', '', 'allSchools');
          }
          else {
            getters.stateSorted('asc','schueler_vorname','schueler_name', 'allSchueler');
          }
          if (rootGetters['auth/canSeeUsers'])
          {
            commit('setAllActiveSchuelerCases', res.data);
            commit('setAllClosedSchuelerCases', res.data);
            if (rootState.auth.schoolType === "SFZ") {
              commit('setAllSchoolsWithActiveSchuelerCases', res.data);
              commit('setAllSchoolsWithClosedSchuelerCases', res.data);
            }
          }
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setAllSchueler', null);
          commit('setLoading', false, {root: true});
        });
    },
    editAssignment({commit, rootGetters}, payload) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.patch(rootGetters.getUrl('/admin/schueler-user-assign-sfz/' + payload.individuallyOrBySchool), payload.schuelerData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            router.push('/administration/schueler-user-assign');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    setAllSchueler({commit}, payload) {
        commit('setAllSchueler', payload.allSchueler);
    },
    setShowActiveCases({commit}, payload) {
        commit('setShowActiveCases', payload.showActiveCases);
    }
  },
  modules: {}
}
