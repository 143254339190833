import { createRouter, createWebHistory } from 'vue-router'
import Login from "../views/Login.vue";
import StartPage from "../views/StartPage.vue";
import store from "../store";

const routes = [
  {
      path: '/login/:school?',
      name: 'Login',
      component: Login
  },
  {
    path: '/',
    name: 'StartPage',
    component: StartPage
  },
  {
    path: '/choose-own-password',
    name: 'ChooseOwnPassword',
    component: () => import('../views/ChooseOwnPassword.vue')
  },
  {
    path: '/password-email-sent',
    name: 'PasswordEmailSent',
    component: () => import('../views/PasswordEmailSent.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/schueler/:id',
    name: 'Betreuungsakt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Betreuungsakt.vue'),
    // Prevent routing, if no pupil has been selected yet
    beforeEnter: (to, from, next) => {
      if (to.path === '/schueler/null') next(false)
      else next()
    }
  },
  {
    path: '/schueler/create',
    name: 'CreateSchueler',
    component: () => import('../views/EditSchueler.vue')
  },
  {
    path: '/schueler/:id/edit',
    name: 'EditSchueler',
    component: () => import('../views/EditSchueler.vue')
  },
  {
    path: '/schueler/:id/betreuungsakt/:selectedBetreuungsaktFunction',
    name: 'BetreuungsaktFunction',
    component: () => import('../views/BetreuungsaktFunction.vue'),
    props: true,
  },
  {
    path: '/administration',
    name: 'Administration',
    component: () => import('../views/Administration.vue'),
    // Prevent routing, if user is no admin
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user && store.state.auth.user.is_admin === 1) next()
      else next(false)
    }
  },
  {
    path: '/administration/admin-log',
    name: 'AdminLog',
    component: () => import('../views/AdminLog.vue'),
    // Prevent routing, if user is no admin
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user && store.state.auth.user.is_admin === 1) next()
      else next(false)
    },
  },
  {
    path: '/administration/all-users',
    name: 'AllUsers',
    component: () => import('../views/AllUsers.vue'),
    // Prevent routing, if user is no admin
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user && store.state.auth.user.is_admin === 1) next()
      else next(false)
    }
  },
  {
    path: '/administration/user/create',
    name: 'CreateUser',
    component: () => import('../views/EditUser.vue'),
    // Prevent routing, if user is no admin
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user && store.state.auth.user.is_admin === 1) next()
      else next(false)
    },
  },
  {
    path: '/administration/user/:id/edit',
    name: 'EditUser',
    component: () => import('../views/EditUser.vue'),
    // Prevent routing, if user is no admin
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user && store.state.auth.user.is_admin === 1) next()
      else next(false)
    }
  },
  {
    path: '/administration/schueler-user-assign',
    name: 'SchuelerUserAssignSFZ',
    component: () => import('../views/SchuelerUserAssignSFZ.vue'),
    // Prevent routing, if user is no admin
    beforeEnter: (to, from, next) => {
      if (store.state.auth.schoolType === "SFZ" && store.state.auth.user && store.state.auth.user.is_admin === 1) next()
      else next(false)
    }
  },
  {
    path: '/administration/schueler-users-assign/:id',
    name: 'SchuelerUsersAssignGY',
    component: () => import('../views/SchuelerUsersAssignGY.vue'),
    // Prevent routing, if user doesn't have the role "SchulpsychologIn"
    beforeEnter: (to, from, next) => {
      if (store.state.auth.schoolType === "GY" && store.state.auth.user && store.state.auth.user.role === "SchulpsychologIn") next()
      else next(false)
    }
  },
  {
    path: '/administration/choose-categories',
    name: 'ChooseCategories',
    component: () => import('../views/ChooseCategories.vue'),
    // Prevent routing, if user is no admin
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user && store.state.auth.user.is_admin === 1) next()
      else next(false)
    }
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('../views/Information.vue')
  },
  {
    path: '/information/changelog',
    name: 'Changelog',
    component: () => import('../views/Changelog.vue')
  },
  {
    path: '/information/corporate-information',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue')
  },
  {
    path: '/information/help',
    name: 'Help',
    component: () => import('../views/Help.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    // if route has school as parameter then set this one, else load school from cookie
    if (to.params.school) {
      // "login/school" is entered by the user as URL. So the school can be fetched from there
      store.dispatch('auth/setSchool', to.params.school);
    }
    else {
      store.dispatch('auth/loadSchool');
    }

    // When the user reloads the page, the state is cleared.
    // The school type must therefore be retrieved again in this case. (FLOG)
    const userReloadsMsdPortal = localStorage.getItem('userReloadsMsdPortal') === 'true';
    if (userReloadsMsdPortal === false && !store.getters['auth/check']) {
      store.dispatch('auth/validateSession');
    }
    if (userReloadsMsdPortal === true) {
      store.dispatch('auth/getSchoolType')
      .then(() => {
        store.dispatch('auth/validateSession');
      });
      localStorage.removeItem('userReloadsMsdPortal');
    }

    next();
})

export default router
