import axios from 'axios';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    userBeingEdited: {
      can_export_betreuungsakt: null,
      is_admin: null,
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      role: null
    },
    userBeingEditedOriginalData: {},
    roleOptions: [],
    adminRightsOfOwnUserHaveBeenRemoved: false,
    userSuccessfullyEdited: false,
    pupilsAssignedToTheUser: null
  },
  getters: {
  },
  mutations: {
    setAdminRightsOfOwnUserHaveBeenRemoved(state, adminRightsOfOwnUserHaveBeenRemoved) {
      state.adminRightsOfOwnUserHaveBeenRemoved = adminRightsOfOwnUserHaveBeenRemoved;
    },
    setRoleOptions(state, roleOptions) {
      state.roleOptions = roleOptions;
    },
    setUserBeingEdited(state, userBeingEdited) {
      state.userBeingEdited = userBeingEdited;
    },
    setUserBeingEditedId(state, userBeingEditedId) {
      state.userBeingEdited.id = userBeingEditedId;
    },
    setUserBeingEditedOriginalData(state, userBeingEditedOriginalData) {
      state.userBeingEditedOriginalData = JSON.parse(JSON.stringify(userBeingEditedOriginalData));
    },
    setUserSuccessfullyEdited(state, userSuccessfullyEdited) {
      state.userSuccessfullyEdited = userSuccessfullyEdited;
    },
    setPupilsAssignedToTheUser(state, pupilsAssignedToTheUser) {
      state.pupilsAssignedToTheUser = pupilsAssignedToTheUser;
    },
  },
  actions: {
    checkPassword({rootGetters}, passwordOfAdmin) {
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/admin/edit-user/check-password'), { passwordOfAdmin: passwordOfAdmin })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDropdownOptions({commit, rootGetters}){
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/admin/edit-user/create'))
          .then((res) => {
            commit('setRoleOptions', res.data.roles);
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    getUserBeingEdited({commit, state, rootGetters}){
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/admin/edit-user/' + state.userBeingEdited.id + '/edit'))
        .then((res) => {
          commit('setUserBeingEdited', res.data);
          commit('setUserBeingEditedOriginalData', res.data);
          commit('setRoleOptions', res.data.role_options);
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setUserBeingEdited', null);
          commit('setUserBeingEditedOriginalData', null);
          commit('setLoading', false, {root: true});
        });
    },
    createUser({commit, rootGetters}, userData) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/admin/edit-user'), userData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    editUser({commit, state, rootGetters, rootState, dispatch}, passwordOfAdmin) {
      let userData = JSON.parse(JSON.stringify(state.userBeingEdited));
      userData.passwordOfAdmin = passwordOfAdmin;
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.patch(rootGetters.getUrl('/admin/edit-user/' + state.userBeingEdited.id), userData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            if (state.userBeingEdited.id === rootState.auth.user.id && rootState.auth.user.is_admin === 1 && userData.is_admin === "0"){
              dispatch('auth/getUser', null, { root: true })
              .then(() => {
                dispatch('setAdminRightsOfOwnUserHaveBeenRemoved', { adminRightsOfOwnUserHaveBeenRemoved : true });
                setTimeout(() => {
                  dispatch('setAdminRightsOfOwnUserHaveBeenRemoved', { adminRightsOfOwnUserHaveBeenRemoved : false });
                }, 30000);
                router.push('/');
              })
              .catch(() => {
                alert('Fehler bei der Abfrage der geänderten Userdaten. Bitte wenden Sie sich an eine Administratorin / einen Administrator Ihres MSD-Portals.')
              });
            }
            else {
              if (state.userBeingEdited.id === rootState.auth.user.id && (state.userBeingEdited.role !== rootState.auth.user.role || state.userBeingEdited.can_export_betreuungsakt !== rootState.auth.user.can_export_betreuungsakt)) {
                dispatch('auth/getUser', null, { root: true })
                .catch(() => {
                  alert('Fehler bei der Abfrage der geänderten Userdaten. Bitte wenden Sie sich an eine Administratorin / einen Administrator Ihres MSD-Portals.')
                });
              }
              dispatch('setUserSuccessfullyEdited', { user_successfully_edited : true });
              router.push('/administration/all-users');
            }
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    deleteUser({commit, state, rootGetters}) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.delete(rootGetters.getUrl('/admin/edit-user/' + state.userBeingEdited.id))
          .then((res) => {
            commit('setLoading', false, {root: true});
            commit('allUsersModule/deleteUserFromState', state.userBeingEdited.id, {root: true});
            commit('setUserBeingEditedId', null);
            resolve(res);
            router.push('/administration/all-users');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            commit('setUserBeingEditedId', null);
            reject(error);
          });
      });
    },
    blockOrUnblockUser({commit, state, rootGetters}, block_user) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.patch(rootGetters.getUrl('/admin/edit-user/' + state.userBeingEdited.id + '/block-or-unblock'), block_user)
          .then((res) => {
            commit('setLoading', false, {root: true});
            commit('setUserBeingEditedId', null);
            resolve(res);
            router.push('/administration/all-users');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            commit('setUserBeingEditedId', null);
            reject(error);
          });
      });
    },
    checkForAssignedPupils({commit, state, rootGetters}){
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/admin/edit-user/' + state.userBeingEdited.id + '/check'))
          .then((res) => {
            commit('setLoading', false, {root: true});
            commit('setPupilsAssignedToTheUser', res.data);
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    setAdminRightsOfOwnUserHaveBeenRemoved({commit}, payload) {
        commit('setAdminRightsOfOwnUserHaveBeenRemoved', payload.adminRightsOfOwnUserHaveBeenRemoved);
    },
    setUserBeingEditedId({commit}, payload) {
        commit('setUserBeingEditedId', payload.userBeingEditedId);
    },
    setUserSuccessfullyEdited({commit}, payload) {
      commit('setUserSuccessfullyEdited', payload.user_successfully_edited);
    },
    setPupilsAssignedToTheUser({commit}, payload) {
        commit('setPupilsAssignedToTheUser', payload.pupilsAssignedToTheUser);
    }
  },
  modules: {}
}
