import axios from 'axios';
import {convertIsoDateToVersionTimestamp} from '@/helperFunctions';

export default {
  namespaced: true,
  state: {
    allChangelogEntries: null,
    currentVersion: null
  },
  getters: {
  },
  mutations: {
    setAllChangelogEntries(state, allChangelogEntries) {
      state.allChangelogEntries = allChangelogEntries;
    },
    setCurrentVersion(state, currentVersionIsoDate) {
      state.currentVersion = convertIsoDateToVersionTimestamp(currentVersionIsoDate);
    }
  },
  actions: {
    getAllChangelogEntries({commit, rootGetters}){
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/api/changelog-entries'))
        .then((res) => {
          commit('setAllChangelogEntries', res.data);
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setAllChangelogEntries', null);
          commit('setLoading', false, {root: true});
        });
    },
    getCurrentVersion({commit, rootGetters, rootState}){
      // The school is removed from the server url, because the current version is fetched from the central db
      commit('config/removeSchoolFromServerUrl', null, {root: true});
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/get-current-version'))
        .then((res) => {
          commit('setCurrentVersion', res.data['current_version']);
          commit('config/addSchoolToServerUrl', rootState.auth.school, {root: true});
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setCurrentVersion', null);
          commit('config/addSchoolToServerUrl', rootState.auth.school, {root: true});
          commit('setLoading', false, {root: true});
        });
    }
  }
}