<template>
  <div
    class="ui teal message"
  >
    <i
      class="close icon"
      @click="successMessageCloseIconClicked()"
    />
    <slot name="messageText" />
  </div>
</template>

<script>
export default {
  name: "BaseSuccessMessage",
  emits: ['successMessageCloseIconClicked'],
  methods: {
    successMessageCloseIconClicked() {
      this.$emit('successMessageCloseIconClicked')
    }
  }
}
</script>

<style scoped>

</style>