/**
 * Capitalize a string
 *
 */
export function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}

/**
 * Convert an ISO date to the version timestamp of the MSD-Portal
 *
 * The date is converted to yyyy.mm.dd.hhmmss
 */
export function convertIsoDateToVersionTimestamp(isoDate) {
  if (!isoDate){
    return '';
  }
  else {
    var dateAndHoursArray = isoDate.split(' ');
    var dateArray = dateAndHoursArray[0].split("-");
    var timeArray = dateAndHoursArray[1].split(":");
    return dateArray[0]+ '.' + dateArray[1] + '.' + dateArray[2] + '.' + timeArray[0] + timeArray[1] + timeArray[2];
  }
}

/**
 * Convert an ISO date to a German date
 *
 * The date is converted to dd.mm.yyyy
 * If there are hours in the isoDate parameter the date is converted to dd.mm.yyyy hh:mm:ss
 */
export function convertIsoDateToGermanDate(isoDate) {
  if (isoDate !== null) {
    var dateAndHoursArray = isoDate.split(' ');
    var dateArray = dateAndHoursArray[0].split("-");
    let hours = dateAndHoursArray[1] ? ' ' + dateAndHoursArray[1] : '';
    // Formats the date in dd.mm.yyyy and if there are hours in dd.mm.yyyy hh:mm:ss
    return dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0] + hours;
  }
  else {
    return "";
  }
}

/**
 * Return the full name of a Betreuungsakt function
 *
 */
export function getBetreuungsaktFunctionFullName(betreuungsaktFunction) {
  switch(betreuungsaktFunction) {
      case "Diagnostisch":
        return "Diagnostische Daten";
      case "Foerderplan":
        return "Förderplan und informelle Diagnostik";
      case "Gutachten":
        return "Gutachten und Berichte";
      default:
        return betreuungsaktFunction;
  }
}