<template>
  <div class="field">
    <div class="ui checkbox">
      <input
        type="checkbox"
        :checked="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      >
      <label v-if="label">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
label {
  font-size: .92857143em !important;
  font-weight: 700 !important;
}
</style>
