import axios from 'axios';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    usersThatShallBeAssignedToTheStudent: null,
    usersSuccessfullyAssignedToTheStudentGy: false
  },
  mutations: {
    setUsersSuccessfullyAssignedToTheStudentGy(state, usersSuccessfullyAssignedToTheStudentGy) {
      state.usersSuccessfullyAssignedToTheStudentGy = usersSuccessfullyAssignedToTheStudentGy;
    }
  },
  actions: {
    editAssignmentGy({commit, rootGetters}, payload) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.patch(rootGetters.getUrl('/api/schueler-users-assign-gy/' + payload.schuelerId), payload.userData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            router.push('/');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    setUsersSuccessfullyAssignedToTheStudentGy({commit}, payload) {
      commit('setUsersSuccessfullyAssignedToTheStudentGy', payload.usersSuccessfullyAssignedToTheStudentGy);
      if (payload.usersSuccessfullyAssignedToTheStudentGy === true) {
        setTimeout(() => { commit('setUsersSuccessfullyAssignedToTheStudentGy', false ); }, 6000);
      }
    }
  }
}