<template>
  <sui-modal>
    <sui-modal-header v-if="header">
      {{ header }}
    </sui-modal-header>
    <sui-modal-content>
      <sui-modal-description>
        <p>
          <slot name="modalText" />
        </p>
      </sui-modal-description>
    </sui-modal-content>
    <sui-modal-actions>
      <slot name="confirmButton" />
      <slot name="cancelButton" />
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
export default {
  name: "BaseConfirmModal",
  props: {
    header: {
      type: String,
      default: ''
    }
  },
}
</script>

<style scoped>

</style>