<template>
  <div class="ui list margins margin-top">
    <div
      v-for="(currentListItem, index) in listItemsArray"
      :key="index"
      class="item"
    >
      <div
        class="cursor"
        :style="changeEntryColor(currentListItem)"
        @click="$emit('expandOrCollapseOneEntry', currentListItem.id)"
      >
        <div class="flexbox-justify-end-align-start">
          <span>
            <i
              v-if="currentListItem.expand_entry"
              class="caret down icon cursor"
            />
            <i
              v-else
              class="caret right icon cursor"
            />
          </span>
          <span class="list_text_margin_right_auto">
            <slot
              name="accordion_title"
              :current-list-item="currentListItem"
            />
          </span>
          <span class="icons_margin margin_top_0_dot_1_em">
            <slot
              name="icons"
              :current-list-item="currentListItem"
              :index="index"
            />
          </span>
        </div>
        <div
          v-if="currentListItem.expand_entry"
          class="list_text_grey list_text_grey_margin_top"
          :style="changeEntryColor(currentListItem)"
        >
          <span
            v-for="index_inner_loop in numberOfContentProperties"
            :key="index_inner_loop"
          >
            <slot
              :name="['content', index_inner_loop].join('_')"
              :current-list-item="currentListItem"
            />
          </span>
        </div>
      </div>
      <div class="ui divider small-margin-top-and-bottom" />
    </div>
  </div>
</template>

<script>

export default {
  name: "BaseListExpandable",
  props: {
    listItemsArray: {
      type: Array,
      required: true
    },
    numberOfContentProperties: {
      type: Number,
      required: true
    },
    highlightEntriesWithDifferentVersions: {
      type: Boolean,
      required: true
    },
    highlightEntriesWithDuplicateFileNames: {
      type: Boolean,
      required: true
    }
  },
  emits: ['expandOrCollapseOneEntry'],
  methods: {
    changeEntryColor: function(entry) {
      var style = {};
      if (this.highlightEntriesWithDifferentVersions === true && entry.divergent_version_entry_id) {
        style.color = 'darkgreen';
      }
      else if (this.highlightEntriesWithDuplicateFileNames === true && entry.duplicate_file_names === true) {
        style.color = 'mediumblue';
      }

      return style;
    }
  }
}
</script>

<style scoped>

</style>