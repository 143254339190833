import axios from 'axios';

export default {
  namespaced: true,
  state: {
    allCategories: null,
    category_to_delete_id: null,
    files_with_category_exist: false
  },
  getters: {

  },
  mutations: {
    setAllCategories(state, all_categories) {
      state.allCategories = all_categories;
    },
    setCategoryToDeleteId(state, category_to_delete_id) {
      state.category_to_delete_id = category_to_delete_id;
    },
    setFilesWithCategoryExist(state, files_with_category_exist) {
      state.files_with_category_exist = files_with_category_exist;
    },
    deleteCategoryFromState(state, id){
     var index = state.allCategories.findIndex(category => category.id === id)
     if (index !== -1){
        state.allCategories.splice(index, 1);
     }
    }
  },
  actions: {
    getAllCategories({commit, rootGetters}){
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/admin/categories'))
        .then((res) => {
          commit('setAllCategories', res.data);
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setAllCategories', null);
          commit('setLoading', false, {root: true});
        });
    },
    editCategories({commit, rootGetters}, categoriesData) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.patch(rootGetters.getUrl('/admin/categories'), categoriesData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    deleteCategory({commit, state, rootGetters}) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.delete(rootGetters.getUrl('/admin/categories/' + state.category_to_delete_id))
          .then((res) => {
            commit('setLoading', false, {root: true});
            commit('chooseCategoriesModule/deleteCategoryFromState', state.category_to_delete_id, {root: true});
            commit('setCategoryToDeleteId', null);
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            commit('setCategoryToDeleteId', null);
            reject(error);
          });
      });
    },
    checkFilesWithCategoryExist({commit, rootGetters}, payload){
      commit('setLoading', true, {root: true});
      commit('setFilesWithCategoryExist', false);
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/admin/categories/' + payload.category_id))
          .then((res) => {
            commit('setLoading', false, {root: true});
            if (res.data === 1){
              commit('setFilesWithCategoryExist', true);
            }
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    setCategoryToDeleteId({commit}, payload) {
        commit('setCategoryToDeleteId', payload.category_id);
    }
  },
  modules: {}
}
