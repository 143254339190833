import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FomanticUI from 'vue-fomantic-ui'
import 'fomantic-ui-css/semantic.min.css'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

/* All components that begin with "Base" are auto imported
and are ready to use in every other component */
const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

const app = createApp(App)
  .use(store)
  .use(router)
  .use(FomanticUI)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )

  app.component(componentName, componentConfig.default || componentConfig)
})

app.mount('#app')
