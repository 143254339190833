<template>
  <div
    class="icons_sort_view"
  >
    <sui-dropdown
      class="sort_dropdown"
      icon="sort"
      button
      pointing="top left"
    >
      Sortieren
      <sui-dropdown-menu>
        <slot
          v-for="index in numberOfContentProperties"
          :key="index"
          :name="['dropdown_item', index].join('_')"
        />
      </sui-dropdown-menu>
    </sui-dropdown>
    <slot name="element_in_the_middle" />
    <slot name="search_icon_button" />
    <slot name="create_new_list_item_button" />
  </div>
</template>

<script>
export default {
  name: "BaseSortDropdown",
  props: {
    numberOfContentProperties: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>

</style>