<template>
  <div
    class="ui info message"
  >
    <slot name="messageText" />
  </div>
</template>

<script>
export default {
  name: "BaseInfoMessage"
}
</script>

<style scoped>

</style>