<template>
  <sui-dimmer active>
    <sui-loader />
  </sui-dimmer>
</template>

<script>
  export default {
    name: 'LoaderComponent'
  }
</script>