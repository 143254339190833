import axios from 'axios';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    canModifyStudentData: false,
    modalInfoAboutDocumentation: false,
    school: null,
    schoolType: null,
    user: null,
    userRoleThatCanSeeAbgeschlosseneBetreuungsfaelleGY: 'SchulpsychologIn',
    nonAuthRoutes: [
      '/reset-password',
      'Login',
      'ResetPassword'
    ]
  },
  getters: {
    canCreateStudents: state => {
      return state.schoolType === 'SFZ' || state.user.role === 'SchulpsychologIn';
    },
    canExportBetreuungsakt: state => {
      return state.schoolType === 'SFZ' || state.user && state.user.can_export_betreuungsakt === 1;
    },
    canSeeUsers: state => {
      return state.schoolType === 'SFZ' && state.user.is_admin || state.schoolType === 'GY' && state.user.role === 'SchulpsychologIn';
    },
    passwordConfirmationError: () => (errors) => {
      return (errors && errors.password
      && errors.password.includes('The password confirmation does not match.')) || false;
    },
    passwordLengthError: () => (errors) => {
      return (errors && errors.password
          && errors.password.includes('The password must be at least 10 characters.')) || false;
    },
    passwordMixedCaseError: () => (errors) => {
      return (errors && errors.password
          && errors.password.includes('The password must contain at least one uppercase and one lowercase letter.')) || false;
    },
    passwordNumberError: () => (errors) => {
      return (errors && errors.password
          && errors.password.includes('The password must contain at least one number.')) || false;
    },
    passwordSymbolError: () => (errors) => {
      return (errors && errors.password
          && errors.password.includes('The password must contain at least one symbol.')) || false;
    },
    userCanNotAccessHome: state => {
      return state.schoolType === 'GY' && state.user && !state.user.role;
    },
    check: state => {
      // When the "ChooseOwnPassword" route is accessed and the user isn't logged in yet
      // the getUser function returns a Html file in form of a string as response (I don't know why)
      // Therefore the check for type object is used here
      return state.user !== null && typeof state.user === 'object';
    },
    checkFullyRegistered: state => {
      return state.user !== null && state.user.status === "fully_registered";
    }
  },
  mutations: {
    setCanModifyStudentData(state, canModifyStudentData) {
      state.canModifyStudentData = canModifyStudentData;
    },
    setModalInfoAboutDocumentation(state, modalInfoAboutDocumentation) {
      state.modalInfoAboutDocumentation = modalInfoAboutDocumentation;
    },
    setSchoolType(state, schoolType) {
      state.schoolType = schoolType;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    setSchool({state, commit}, school) {
        if (state.school === school) return;

        state.school = school;
        document.cookie = 'msd_school=' + school + '; path=/; SameSite=Lax; Secure; expires=Tue, 19 Jan 2038 03:14:07 UTC;'; // maximum cookie lifetime
        commit('config/addSchoolToServerUrl', school, {root: true});
    },
    loadSchool({state, dispatch}) {
        if (state.school) {
            return true;
        }

        const hasSchoolCookie = document.cookie.split('msd_school=')[1] !== undefined;
        if (hasSchoolCookie) {
            const schoolFromCookie = document.cookie.split('msd_school=')[1].split(';')[0];
            dispatch('setSchool', schoolFromCookie);
            return true;
        } else {
            return false;
        }
    },
    login({dispatch, commit, rootGetters}, loginData) {
      commit('setLoading', true, {root: true});
      // When the user closes the tab, in App.vue
      // userReloadsMsdPortal is written to the localStorage with true (FLOG)
      localStorage.removeItem('userReloadsMsdPortal');
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/login'), loginData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            dispatch('getUser', 'fromLogin');
          })
          .catch((error) => {
            commit('setUser', null);
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    logout({commit, rootGetters}) {
      commit('setLoading', true, {root: true});
      axios.post(rootGetters.getUrl('/logout'))
        .then(() => {
          commit('schuelerModule/setSchuelerId', null, { root: true });
          router.push('/');
          commit('setUser', null);
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setUser', null);
          commit('setLoading', false, {root: true});
        });
    },
    getSchoolType({state, dispatch, commit, rootGetters}, schoolInput){
      commit('setLoading', true, {root: true});
      // The school is removed from the server url, because the school type is fetched from the central db
      commit('config/removeSchoolFromServerUrl', null, {root: true});
      let school = schoolInput ?? state.school;
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/get-school-type/' + school))
          .then((res) => {
            commit('setSchoolType', res.data);
            commit('config/addSchoolToServerUrl', state.school, {root: true});
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            dispatch('logout');
            alert('Bei der Abfrage der Schulart ist es zu einem Fehler gekommen. Bitte wenden Sie sich an die/den AdministratorIn.');
            commit('setSchoolType', null);
            commit('config/addSchoolToServerUrl', state.school, {root: true});
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    forgotPassword({rootGetters}, email) {
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/forgot-password'), {email: email})
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resetPassword({commit, rootGetters}, data) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/reset-password'), data)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            setTimeout(() => {
              router.push('/');
            }, 5000);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    claimCsrfToken({commit, rootGetters}) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/sanctum/csrf-cookie'))
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setUser', null);
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    validateSession({dispatch}) {
      dispatch('getUser', 'fromValidateSession')
      .catch(() => {
      });
    },
    getUser({state, commit, dispatch, rootGetters}, from) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/api/user'))
          .then((res) => {
            dispatch('setUser', res.data)
            .then(() => {
              if (from === "fromLogin" && state.user.status === "no_pw_chosen_yet" && window.location.pathname !== "/choose-own-password"){
                router.push('/password-email-sent');
              }
              else if (from === "fromLogin" && state.user.status === "fully_registered"){
                router.push('/');
              }
              commit('setLoading', false, {root: true});
              resolve(res);
            });
          })
          .catch((error) => {
            commit('setUser', null);
            commit('setLoading', false, {root: true});
            if (window.location.pathname !== "/choose-own-password" && window.location.pathname !== "/reset-password"){
              router.push({name: 'Login'});
            }
            reject(error);
          });
      });
    },
    storeOwnPassword({dispatch, commit, rootGetters}, data) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/api/own-password'), data)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            dispatch('getSchoolType')
            .then(() => {
              dispatch('getUser', 'fromStoreOwnPassword');
              setTimeout(() => {
                router.push('/');
              }, 5000);
              setTimeout(() => {
                commit('setModalInfoAboutDocumentation', true);
              }, 6000);
            });
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    setUser({ commit }, user) {
      return new Promise((resolve) => {
        commit('setUser', user);
        resolve();
      });
    },
  },
  modules: {}
}
