<template>
  <sui-menu
    fluid
    :widths="menu_width"
    icon="labeled"
    size="mini"
    class="bottom fixed"
  >
    <router-link
      is="sui-menu-item"
      v-if="schoolType === 'SFZ' || userRole"
      to="/"
      class="item"
      active-class="active"
    >
      <sui-icon
        name="home"
        size="big"
      />
      Home
    </router-link>
    <router-link
      is="sui-menu-item"
      v-if="schoolType === 'SFZ' || userRole"
      :to="'/schueler/' + schueler_id"
      :class="betreuungsakt_item_class"
      active-class="active"
      :title="betreuungsakt_item_title"
    >
      <sui-icon
        name="address card"
        size="big"
      />
      Betreuungsakt
    </router-link>
    <router-link
      is="sui-menu-item"
      v-if="is_admin"
      :to="'/administration'"
      class="item"
      active-class="active"
    >
      <sui-icon
        name="cog"
        size="big"
      />
      Administration
    </router-link>
    <router-link
      is="sui-menu-item"
      :to="'/information'"
      class="item"
      active-class="active"
    >
      <sui-icon
        name="info"
        size="big"
      />
      Informationen
    </router-link>
  </sui-menu>
</template>

<script>
import store from "@/store";

export default {
  name: "BottomMenu",
  computed: {
    schoolType() {
      return store.state.auth.schoolType;
    },
    schueler_id() {
      return store.state.schuelerModule.schueler.id;
    },
    betreuungsakt_item_class() {
      if (store.state.schuelerModule.schueler.id){
        return "item";
      }
      else{
        return "item menu_item_disabled";
      }
    },
    betreuungsakt_item_title() {
      if (store.state.schuelerModule.schueler.id){
        return "";
      }
      else{
        return "Ist anklickbar, sobald ein(e) SchülerIn ausgewählt wurde.";
      }
    },
    is_admin() {
      return store.state.auth.user.is_admin === 1;
    },
    menu_width() {
      if (store.state.auth.user.is_admin === 1 && (this.schoolType === 'SFZ' || this.userRole)){
        return 4;
      }
      else if (this.schoolType === 'GY' && !this.userRole) {
        return 2;
      }
      else {
        return 3;
      }
    },
    userRole() {
      return store.state.auth.user.role;
    }
  }
}
</script>

<style scoped>
.menu_item_disabled {
  cursor: default;
  background-color: transparent;
  color: rgba(40,40,40,.3);
  opacity: .45;
}
</style>
