<template>
  <div
    class="flexbox-justify-end-align-center"
  >
    <span
      :class="searchBarCSSClass"
    >
      <slot />
      <i class="search icon" />
    </span>
    <span class="times_icon_span">
      <button
        class="ui tertiary icon button times_icon_button"
        @click="closeIconClicked()"
      >
        <i class="times icon" />
      </button>
    </span>
  </div>
</template>

<script>

export default {
  name: "BaseSearchBar",
  props: {
    searchBarCSSClass: {
      type: String,
      required: false,
      default: "ui icon input with_margins search_bar"
    }
  },
  emits: ['closeIconClicked'],
  methods: {
   closeIconClicked() {
     this.$emit('closeIconClicked')
   }
 }
}
</script>

<style scoped>

.ui.icon.input.without_margins {
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ui.icon.input.with_margins {
  width: 100%;
  margin-top: 0.9em !important;
  margin-bottom: 0.3em !important;
}

</style>