import axios from "axios";
import router from '../../router'

export default {
  namespaced: true,
  state: {
    requestReturnedForbiddenResponseStatus: false,
    serverDomain: process.env.VUE_APP_MSD_API, // API server Domain
    serverUrl: null // API server URL
  },
  getters: {  },
  mutations: {
    addSchoolToServerUrl(state, school) {
      state.serverUrl = state.serverDomain + '/' + school;
    },
    removeSchoolFromServerUrl(state) {
      state.serverUrl = state.serverDomain;
    },
    setRequestReturnedForbiddenResponseStatus(state, requestReturnedForbiddenResponseStatus) {
      state.requestReturnedForbiddenResponseStatus = requestReturnedForbiddenResponseStatus;
    }
  },
  actions: {
    config({commit, dispatch}) {
      // configure axios
      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        switch (error.response.status) {
          case 401:
            commit('auth/setUser', null, {root: true});
            break;
          case 403:
            dispatch('setRequestReturnedForbiddenResponseStatus', { requestReturnedForbiddenResponseStatus : true });
            // Fetch the user because the request may have returned a 403 response because the user's role or admin rights have been changed (FLOG - 06.12.2023).
            dispatch('auth/getUser', null, { root: true })
            .then(() => {
              router.push('/');
            })
            break;
          }

          return Promise.reject(error);
      });
    },
    setRequestReturnedForbiddenResponseStatus({commit}, payload) {
      commit('setRequestReturnedForbiddenResponseStatus', payload.requestReturnedForbiddenResponseStatus);
    }
  },
  modules: {}
}
