export function isAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent(userRole, userId, student) {
  if (userRole === 'Hauptverantwortliche Lehrkraft') {
    return Array.isArray(student.users_gy) && student.users_gy.some(user => user.pivot.user_id === userId);
  }
  return false;
}

/**
 * Checks, if a user is allowed to modify the data of a student
 *
 * If no student is passed into the function for the role "Hauptverantwortliche Lehrkraft" it returns true in order to express that
 * the user role "Hauptverantwortliche Lehrkraft" is allowed to modify student data under certain conditions
 */
export function checkCanModifyStudentData(schoolType, userRole, userId, student = null) {
  if (userRole === 'Hauptverantwortliche Lehrkraft' && !student) {
    return true;
  }

  if (schoolType === 'GY' && (userRole === 'Fachlehrkraft' || userRole === 'SozialpädagogIn' || userRole === 'TherapeutIn')) {
    return false;
  } else if (userRole === 'Hauptverantwortliche Lehrkraft') {
    return isAuthenticatedHauptverantwortlicheLkUserIsAssignedToCurrentStudent(userRole, userId, student);
  } else {
    return true;
  }
}