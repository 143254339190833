<template>
  <Home v-if="userCanNotAccessHome === false"></Home>
  <Administration v-else-if="userCanNotAccessHome === true"></Administration>
</template>

<script>
import store from "../store";
import Administration from "../views/Administration";
import Home from "../views/Home";

export default {
  name: "StartPage",
  components: {Administration, Home},
  computed: {
    userCanNotAccessHome() {
      return store.getters['auth/userCanNotAccessHome'];
    }
  }
}
</script>

<style scoped>
</style>
