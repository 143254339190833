<template>
  <sui-menu
    size="mini"
    class="fixed"
  >
    <sui-menu-item>
      <svg
        id="svg"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        viewBox="0, 0, 400,254.35897435897436"
      >
        <g id="svgg">
          <path
            id="path0"
            d="M0.000 199.873 L 0.000 254.453 54.580 254.453 L 109.160 254.453 109.160 199.873 L 109.160 145.293 54.580 145.293 L 0.000 145.293 0.000 199.873 "
            stroke="none"
            fill="#94941c"
            fill-rule="evenodd"
          />
          <path
            id="path1"
            d="M290.840 54.580 L 290.840 109.160 345.420 109.160 L 400.000 109.160 400.000 54.580 L 400.000 0.000 345.420 0.000 L 290.840 0.000 290.840 54.580 "
            stroke="none"
            fill="#dc1c2c"
            fill-rule="evenodd"
          />
          <path
            id="path2"
            d="M290.840 199.873 L 290.840 254.453 345.420 254.453 L 400.000 254.453 400.000 199.873 L 400.000 145.293 345.420 145.293 L 290.840 145.293 290.840 199.873 "
            stroke="none"
            fill="#9474b4"
            fill-rule="evenodd"
          />
          <path
            id="path3"
            d="M0.000 54.580 L 0.000 109.160 54.580 109.160 L 109.160 109.160 109.160 54.580 L 109.160 0.000 54.580 0.000 L 0.000 0.000 0.000 54.580 "
            stroke="none"
            fill="#2c5ca4"
            fill-rule="evenodd"
          />
          <path
            id="path4"
            d="M145.208 145.462 C 145.115 145.555,145.038 170.117,145.038 200.042 L 145.038 254.453 200.001 254.453 L 254.963 254.453 254.899 199.936 L 254.835 145.420 200.107 145.356 C 170.006 145.320,145.302 145.368,145.208 145.462 "
            stroke="none"
            fill="#fcb424"
            fill-rule="evenodd"
          />
          <path
            id="path5"
            d="M145.038 54.580 L 145.038 109.160 200.000 109.160 L 254.962 109.160 254.962 54.580 L 254.962 0.000 200.000 0.000 L 145.038 0.000 145.038 54.580 "
            stroke="none"
            fill="#e45414"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </sui-menu-item>
    <div
      v-if="school && school !== 'null' && !auth"
      class="msd_header_wrapper"
    >
      <div
        id="msd_header"
        class="text_very_big_bold"
      >
        MSD-Portal:
      </div>
      <div>
        <button
          id="school_button"
          class="ui tiny button"
          @click="$emit('changeSchool')"
        >
          {{ school }}
        </button>
      </div>
    </div>
    <div
      v-else-if="school && school !== 'null' && auth"
      class="msd_header_wrapper"
    >
      <div
        class="text_very_big_bold"
      >
        MSD-Portal: {{ school }}
      </div>
    </div>
    <div
      v-else
      class="msd_header_wrapper"
    >
      <div
        class="text_very_big_bold"
      >
        MSD-Portal
      </div>
    </div>
    <sui-menu-item
      v-if="auth"
      position="right"
    >
      <sui-dropdown
        icon="user"
        pointing="top right"
      >
        <sui-dropdown-menu>
          <sui-dropdown-item
            text="Logout"
            @click="logout"
          />
        </sui-dropdown-menu>
      </sui-dropdown>
    </sui-menu-item>
  </sui-menu>
</template>

<script>
import store from "../store";

export default {
  name: "TopMenu",
  props: {
    auth: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeSchool'],
  computed: {
    // In ResetPasswort when the user tries to switch to a school that doesn't exit and then closes and opens the browser
    // 'null' as string is written into the school state. I don't know why. That's the reason for the check, if school !== 'null'
    // in this component.
    school() {
      return store.state.auth.school;
    },
  },
  methods: {
    logout: function () {
      store.dispatch('auth/logout');
    }
  }
}
</script>

<style scoped>
.ui.menu {
  height: 35px;
}
.ui.dropdown {
  font-size: 1rem !important;
}
.msd_header_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#msd_header {
  margin-right: 0.1em;
}
#school_button {
  font-size: 1rem !important;
  padding: .5em .5em .5em !important;
}
</style>
