<template>
  <div class="ui list margins margin-top">
    <div
      v-for="(currentListItem, index) in listItemsArray"
      :key="index"
      class="item"
    >
      <div
        :class="wrapperClass"
        :style="changeEntryColor(currentListItem)"
      >
        <span class="list_text_margin_right_auto">
          <span>
            <slot
              name="header"
              :current-list-item="currentListItem"
            />
          </span>
          <span
            v-for="index_inner_loop in numberOfContentProperties"
            :key="index_inner_loop"
            class="list_text_grey"
            :style="changeEntryColor(currentListItem)"
          >
            <slot
              :name="['content', index_inner_loop].join('_')"
              :current-list-item="currentListItem"
            />
          </span>
        </span>
        <span :class="marginClasses">
          <slot
            name="icons"
            :current-list-item="currentListItem"
            :index="index"
          />
        </span>
      </div>
      <div class="ui divider small-margin-top-and-bottom" />
    </div>
  </div>
</template>

<script>

export default {
  name: "BaseList",
  props: {
    listItemsArray: {
      type: Array,
      required: true
    },
    numberOfContentProperties: {
      type: Number,
      required: true
    },
    iconsOnTop: {
      type: Boolean,
      required: false
    },
    highlightEntriesWithDifferentVersions: {
      type: Boolean,
      required: false
    },
    highlightEntriesWithDuplicateFileNames: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    wrapperClass() {
      if (this.iconsOnTop){
        return "flexbox-justify-end-align-start";
      }
      else {
        return "flexbox-justify-end-align-center";
      }
    },
    marginClasses() {
      if (this.iconsOnTop){
        return "icons_margin margin_top_0_dot_1_em";
      }
      else {
        return "icons_margin";
      }
    }
  },
  methods: {
    changeEntryColor: function(entry) {
      var style = {};
      if (this.highlightEntriesWithDifferentVersions === true && entry.divergent_version_entry_id) {
        style.color = 'darkgreen';
      }
      else if (this.highlightEntriesWithDuplicateFileNames === true && entry.duplicate_file_names === true) {
        style.color = 'mediumblue';
      }

      return style;
    }
  }
}
</script>

<style scoped>

</style>