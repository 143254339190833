import axios from 'axios';

export default {
  namespaced: true,
  state: {
    allAdminLogEntries: null
  },
  getters: {
  },
  mutations: {
    setAllAdminLogEntries(state, allAdminLogEntries) {
      state.allAdminLogEntries = allAdminLogEntries;
    }
  },
  actions: {
    getAllAdminLogEntries({commit, rootGetters}){
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/admin/admin-log-entries'))
        .then((res) => {
          commit('setAllAdminLogEntries', res.data);
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setAllAdminLogEntries', null);
          commit('setLoading', false, {root: true});
        });
    }
  }
}