<template>
  <sui-modal>
    <form
      method="POST"
      enctype="multipart/form-data"
      @submit.prevent="$emit('submit')"
    >
      <sui-modal-content class="modal-content">
        <sui-modal-description class="modal-description">
          <slot name="firstField" />
          <div style="margin-bottom: 1.2rem !important">
            <div
              class="flexbox-justify-end-align-start cursor"
              @click="$emit('changeTinyMcesHeight')"
            >
              <span>
                <i
                  v-if="filesAccordionExpanded"
                  class="caret down icon cursor"
                />
                <i
                  v-else
                  class="caret right icon cursor"
                />
              </span>
              <span
                v-if="editing && oldFiles.length"
                class="list_text_margin_right_auto text_bold"
              >
                Aktuell hochgeladene Dateien
              </span>
              <span
                v-else
                class="list_text_margin_right_auto text_bold"
              >
                Dateien hochladen
              </span>
            </div>
            <div
              v-if="editing && oldFiles.length && filesAccordionExpanded"
              style="margin-bottom: 1rem !important; margin-top: 0.8rem !important;"
            >
              <div
                v-for="(oldFile, index) in oldFiles"
                :key="index"
                style="margin-bottom: 0.5em !important; word-break: break-all;"
              >
                <input
                  v-model="oldFile.original_file_name_file_name"
                  class="create_entry_modal_file_names"
                  type="text"
                >
                .{{ oldFile.original_file_name_file_extension }}
                <i
                  class="times icon cursor"
                  title="L&ouml;schen"
                  @click="$emit('clickDeleteFileButton', oldFile.id)"
                />
                <br>
              </div>
            </div>
            <div
              v-if="filesAccordionExpanded"
              style="margin-top: 1.2rem !important"
            >
              <i
                v-if="filesBeingSelected && oldFiles && ((oldFiles.length + filesBeingSelected.length) < 3)"
                style="margin-bottom: 0.6em !important"
                class="plus circle icon cursor"
                title="Datei hinzufügen"
                @click="$emit('clickFileInput', $event)"
              />
              <label
                v-if="editing && oldFiles.length < 3"
                class="text_bold"
              >
                Datei hinzufügen (insg. max. 3 möglich)
              </label>
              <label
                v-else-if="editing && oldFiles.length > 2"
                class="text_bold"
                style="line-height:130%;"
              >
                Falls Sie neue Dateien hinzufügen möchten, entfernen Sie bitte zuerst angehängte Dateien.
              </label>
              <label
                v-else
                class="text_bold"
              >
                Datei hinzufügen (max. 3 Dateien)
              </label>
              <br>
              <div
                v-if="oldFiles.length < 3"
                :class="{ add_margin_top: oldFiles.length + filesBeingSelected.length === 3 }"
              >
                <div
                  v-for="(fileBeingSelected, index) in filesBeingSelected"
                  :key="index"
                  style="margin-bottom: 0.5em; word-break: break-all;"
                >
                  {{ fileBeingSelected.name }}
                </div>
              </div>
              <input
                id="file"
                ref="fileInput"
                type="file"
                name="antrag"
                class="create_entry_modal_input_margin"
                style="display:none;"
                @change="$emit('selectFile', $event)"
              >
            </div>
          </div>
          <slot name="textarea" />
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions class="modal-actions">
        <sui-button
          v-if="editing"
          class="create_entry_modal_buttons create_entry_save_changes_button"
          type="submit"
        >
          Änderungen speichern
        </sui-button>
        <sui-button
          v-else
          class="create_entry_modal_buttons create_entry_modal_create_entry_button"
          type="submit"
        >
          Eintrag erstellen
        </sui-button>
        <sui-button
          class="create_entry_modal_buttons"
          type="button"
          @click="$emit('clickCancelButton')"
        >
          abbrechen
        </sui-button>
      </sui-modal-actions>
    </form>
  </sui-modal>
</template>

<script>
export default {
  name: "BaseCreateEntryModal",
  props: {
    editing: {
      type: Boolean,
      required: true
    },
    oldFiles: {
      type: Array,
      required: true
    },
    filesBeingSelected: {
      type: Array,
      required: true
    },
    filesAccordionExpanded: {
      type: Boolean,
      required: true
    },
  },
  emits: ['submit', 'selectFile', 'clickCancelButton', 'clickFileInput', 'clickDeleteFileButton', 'changeTinyMcesHeight'],
  mounted() {
    this.$refs.fileInput.value = null;
  }
}
</script>

<style scoped>
.add_margin_top {
  margin-top: 1.2em !important;
}
</style>